import * as types from './actionsTypes';
import axios from 'axios';
import { Config } from '../config/index';

const leadersFormData = {
    batting: [
        {typeOfStatistics:'BattingAvg', name:'Batting Average'},
        {typeOfStatistics:'SluggingPct', name:'Slugging Percentage'},
        {typeOfStatistics:'OnBasePct', name:'On Base Percentage'},
        {typeOfStatistics:'RunScored', name:'Points marqués'},
        {typeOfStatistics:'Hits', name:'Hits'},
        {typeOfStatistics:'RunsBattedIn', name:'Points produits'},
        {typeOfStatistics:'Doubles', name:'Doubles'},
        {typeOfStatistics:'Triples', name:'Triples'},
        {typeOfStatistics:'HomeRuns', name:'Home Runs'},
        {typeOfStatistics:'TotalBases', name:'Total Bases'},
        {typeOfStatistics:'Walks', name:'But sur balles'},
        {typeOfStatistics:'HitByPitch', name:'Hit by Pitch'},
        {typeOfStatistics:'SacrificeFlies', name:'Flies Sacrifices'},
        {typeOfStatistics:'SacrificeBunts', name:'Bunts Sacrifices'},
        {typeOfStatistics:'StolenBases', name:'Bases volées'},
        {typeOfStatistics:'TotalPlateAppearances', name:'Nombre d\'apparitions'},
        {typeOfStatistics:'AtBats', name:'At Bats'},
        {typeOfStatistics:'StealAttempts', name:'Tentatives de vol'},
        {typeOfStatistics:'Strikeouts', name:'Strikeouts'},
        {typeOfStatistics:'CaughtStealing', name:'Pris en vol'},
        {typeOfStatistics:'GroundedIntoDoublePlay', name:'Frappes en double jeu'}

    ],
    pitching: [
        {typeOfStatistics:'Era', name:'ERA'},
        {typeOfStatistics:'OpposingBattingAverage', name:'Batting Average adversaires'},
        {typeOfStatistics:'InningsPitched', name:'manches lancées'},
        {typeOfStatistics:'BattersStruckOut', name:'Strikeouts'},
        {typeOfStatistics:'BatterStruckOutLooking', name:'Strikeouts looking'},
        {typeOfStatistics:'Wins', name:'Victoires'},
        {typeOfStatistics:'Saves', name:'Sauvegardes'},
        {typeOfStatistics:'Appearances', name:'Matches lancés'},
        {typeOfStatistics:'GamesStarted', name:'Matches débutés'},
        {typeOfStatistics:'GameFinished', name:'Matches finis'},
        {typeOfStatistics:'GamesInRelief', name:'Matches relevés'},
        {typeOfStatistics:'SacrificeBuntsAllowed', name:'Bunts sacrifices accordés'},
        {typeOfStatistics:'SacrificeFliesAllowed', name:'Flies sacrifices accordées'},
        {typeOfStatistics:'Losses', name:'Défaites'},
        {typeOfStatistics:'RunnersPickedOff', name:'Picked out'},
        {typeOfStatistics:'WildPitches', name:'Wild pitches'},
        {typeOfStatistics:'Balks', name:'Balks'},
        {typeOfStatistics:'HitBatters', name:'frappeurs touchés'},
        {typeOfStatistics:'IntentionalWalksAllowed', name:'Buts sur balles intentionnels'},
        {typeOfStatistics:'HitsAllowed', name:'Hits accordés'},
        {typeOfStatistics:'RunsAllowed', name:'Points accordés'},
        {typeOfStatistics:'EarnedRunsAllowed', name:'Points mérités'},
        {typeOfStatistics:'WalksAllowed', name:'Buts sur balles'},
        {typeOfStatistics:'DoubleAllowed', name:'Doubles accordés'},
        {typeOfStatistics:'TriplesAllowed', name:'Triples accordés'},
        {typeOfStatistics:'HomeRunsAllowed', name:'Home runs accordés'}
    ],
    fielding: [
        {typeOfStatistics:'FieldingPercentage', name:'Fielding Percentage'},
        {typeOfStatistics:'Chances', name:'Chances'},
        {typeOfStatistics:'PutOuts', name:'Putouts'},
        {typeOfStatistics:'Assists', name:'Passes décisives'},
        {typeOfStatistics:'FieldingDoublePlays', name:'Double jeux'},
        {typeOfStatistics:'Errors', name:'Erreurs'}
    ],
    catching: [
        {typeOfStatistics:'PassedBalls', name:'Balles passées'},
        {typeOfStatistics:'CatchersInterference', name:'interférences'},
        {typeOfStatistics:'StealAttemptsAgainst', name:'Tentatives de vols'},
        {typeOfStatistics:'StolenBasesAgainst', name:'Bases volées'},
        {typeOfStatistics:'CaughtStealingBy', name:'Pris en vol'}  
    ]
};

export const saveLeadersList = (obj) => async (dispatch) =>{
    dispatch({ type: types.STATISTICS_LEADERS_SET_SEARCH, payload: obj });
};

export const initializeExplorer = (queryParams) => async (dispatch) =>{

    let result = {
        category: 'Batting',
        typeOfStatistics: 'BattingAvg'
    };


    // Verify query String Now
    if (queryParams.get("c") === null){
        dispatch({ type: types.STATISTICS_LEADERS_SET_SEARCH, payload: result });
        return;
    }
    if (queryParams.get("s") === null){
        dispatch({ type: types.STATISTICS_LEADERS_SET_SEARCH, payload: result });
        return;
    }

    switch(queryParams.get("c")){
        case 'Batting':
            if (leadersFormData.batting.find((o,i)=> {
                if (o.typeOfStatistics === queryParams.get("s"))
                {
                    return true;
                }
                return false;
            }))
            {
                result.category = queryParams.get("c");
                result.typeOfStatistics = queryParams.get("s");
                dispatch({ type: types.STATISTICS_LEADERS_SET_SEARCH, payload: result });
            }
            break;
        case 'Pitching':
            if (leadersFormData.pitching.find((o,i)=> {
                if (o.typeOfStatistics === queryParams.get("s"))
                {
                    return true;
                }
                return false;
            }))
            {
                result.category = queryParams.get("c");
                result.typeOfStatistics = queryParams.get("s");
                dispatch({ type: types.STATISTICS_LEADERS_SET_SEARCH, payload: result });
            }
            break;
        case 'Catching':
            if (leadersFormData.catching.find((o,i)=> {
                if (o.typeOfStatistics === queryParams.get("s"))
                {
                    return true;
                }
                return false;
            }))
            {
                result.category = queryParams.get("c");
                result.typeOfStatistics = queryParams.get("s");
                dispatch({ type: types.STATISTICS_LEADERS_SET_SEARCH, payload: result });
            }
            break;
        case 'Fielding':
            if (leadersFormData.fielding.find((o,i)=> {
                if (o.typeOfStatistics === queryParams.get("s"))
                {
                    return true;
                }
                return false;
            }))
            {
                result.category = queryParams.get("c");
                result.typeOfStatistics = queryParams.get("s");
                dispatch({ type: types.STATISTICS_LEADERS_SET_SEARCH, payload: result });
            }
            break;
        default:
            dispatch({ type: types.STATISTICS_LEADERS_SET_SEARCH, payload: result });
            break;
    }
};

export const loadLeadersData = (sport, year, division, category, typeOfStatistics)  => async (dispatch) => {
    try
    {
        dispatch({ type: types.STATISTICS_LEADERS_KEY_DATA_LOADING, payload: null });
        let payload = await axios.get(`${Config.api}/Statistics/Leaders/${sport}/${division}/${year}/${typeOfStatistics}`);
        if (payload.status === 204)
        {
            dispatch({ type: types.STATISTICS_LEADERS_KEY_DATA, payload: null });
            return;
        }
        payload.data.key = `${category}_${typeOfStatistics}`;
        dispatch({ type: types.STATISTICS_LEADERS_KEY_DATA, payload: payload.data });
    }
    catch(error){
        dispatch({ type: types.STATISTICS_LEADERS_KEY_DATA_LOADING_ERROR, payload: null });
    }
};

export const loadStatisticsExplorerLeadersBaseData = () => async (dispatch) => {
    try {
        dispatch({ type: types.STATISTICS_BASE_LEADER_DATA, payload: leadersFormData });
    } catch (error) {
        dispatch({ type: types.STATISTICS_BASE_LEADER_DATA, payload: null });
    }
};


