import * as types from "../actions/actionsTypes";

// Reducer named statisticsSummary
const initialState = {
    loaded:false,
    title: 'not set',
    data: null
};

export default function statisticsSummaryReducer(state = initialState, action) {
    switch (action.type) {
        case types.STATISTICS_SUMMARY:
            return {...state, loaded:true, title: 'yeap !', data: action.payload};
        default:
            return state;
    }
};