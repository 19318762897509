import React from 'react';
import PropTypes from "prop-types";
import NewsFeedSummary from './NewsFeedSummary';
import PodcastFeedSummary from './PodcastFeedSummary';
import VideoFeedSummary from './VideoFeedSummary';
const FeedItem = (props) => {

    /* If the item is not defined or no data (means person), don't display it */
    if (props.item === undefined)
    {
        return '';
    }


    const it = () => {
        switch(props.item.typeOfFeed){
            case 'News':
                return <NewsFeedSummary item={props.item}/>;
            case "Podcast":
                return <PodcastFeedSummary item={props.item}/>;
            case "Video":
                return <VideoFeedSummary item={props.item} autoplay={props.autoplay}/>;
            default:
                return <div></div>;
        }
    };

    return (
        <div>
            {it()}
        </div>
    );
}

/* properties : item and index are required */
FeedItem.propTypes = {
    item: PropTypes.object,
    autoplay: PropTypes.bool
};

export default FeedItem;