
export const DefaultTheme = {

    primary: {
        main: '#1c355e'
    },
    pageBackground: {
        default:'#c5cfdb'
    },
    text: {
        primary:'#000'
    }

}