import * as types from "../actions/actionsTypes";

// Reducer named newsReducer
const initialState = {
    loaded:false,
    error: false,
    data: null,
    youtubeLive: null
};

export default function newsReducer(state = initialState, action) {
    switch (action.type) {
        case types.LATEST_NEWS:
            return {...state, loaded:true, data: action.payload};
        case types.LATEST_NEWS_ERROR:
            return {...state, loaded:true, data: null, error: true};
        case types.YOUTUBE_LIVE_ON_PROGRESS:
            return {...state, youtubeLive: action.payload};
        default:
            return state;
    }
};