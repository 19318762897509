import React, { Component } from 'react';
import Navigation from '../components/navigation/navigation';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
          <Navigation />
          <div>
          {this.props.children}
          </div>
      </div>
    );
  }
}
