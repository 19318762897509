import React from 'react';
import PropTypes from "prop-types";
import YoutubeLiveEvent from './YoutubeLiveEvent';

const DailyEvent = (props) => {

    if (props.item === undefined)
    {
        return '';
    }

    let liveEvents = props.item.liveEvents.map(function(item, index){        
        return <YoutubeLiveEvent key={index} item={item}/>;});

    return (
        <div className="daily-event">
            <div className="daily-event__date">
                <div className="daily-event__day-name">
                    {props.item.dayName}
                </div>
                <div className="daily-event__day">
                    {props.item.day} {props.item.month}
                </div>
            </div>
            <React.Fragment>
                {liveEvents}
            </React.Fragment>
        </div>
    );
}

DailyEvent.propTypes = {
    item: PropTypes.object
};

export default DailyEvent;