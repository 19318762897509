import * as types from './actionsTypes';
import axios from 'axios';
import { Config } from '../config/index';


export const loadStatisticsSummaryApi = (sport, league, year) => async (dispatch) => {
    try {
        const payload = await axios.get(Config.api + '/Statistics/Leaders/Baseball/D1/Summary');
        dispatch({ type: types.STATISTICS_SUMMARY, payload: payload.data });
    } catch (error) {
        dispatch({ type: types.STATISTICS_SUMMARY, payload: null });
    }
};


