import React, {Component} from 'react';
import { connect } from "react-redux"; 
import { loadLatestNewsApi } from "../../actions/newsActions";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import LoadError from '../error/LoadError';
import FeedItem from './FeedItem';
import YoutubeLive from './YoutubeLive';

class LatestNews extends Component {
    constructor(props) {
        super(props)
        // load the data from the initFn set in mapDispatchToProps
        this.props.initFn();
      }

    render() {
        if (!this.props.newsReducer.loaded){
            const skeletons = Array.apply(null, Array(5)).map(n=> <div className="news-feed news-feed--skeleton"><Stack spacing={1}><Skeleton variant="text" height={40} /><Skeleton variant="rectangular" height={210}/></Stack></div>);
            // Data not yet loaded
            return (
                <div>{skeletons}</div>
            );
        }
        if (this.props.newsReducer.error)
        {
            return <LoadError />
        }

        let autoplay = false;
        let liveEvents = '';
        if (this.props.newsReducer.youtubeLive !== undefined && this.props.newsReducer.youtubeLive !== null) 
        {
 
            liveEvents = this.props.newsReducer.youtubeLive.map(function(item, index){ 
                let ap = false;

                if (!autoplay){
                    autoplay = true;
                    ap = true;
                }
                return <YoutubeLive key={index} autoplay={ap} item={item} />
            });
        }

        const news = this.props.newsReducer.data.map(function(item, index){ 
            if (item.typeOfFeed === 'Video' && !autoplay){
                let dt = new Date(item.publicationDate);
                let current = new Date();
                let seconds = (current - dt) / 1000;
                autoplay = seconds < 86401;
            }             
            return <FeedItem key={index} item={item} autoplay={autoplay}/>;});


        return (
                <div className="fb-standard-box latest-news">
                    {liveEvents}
                    {news}
                </div>
            );
        }

}

const mapDispatchToProps = dispatch => {
    return {
        // Put in the props the dispatch to load the data
        initFn: () => dispatch(loadLatestNewsApi()),
    }
}

const mapStateToProps = state => ({
    ///...state,
    newsReducer: state.newsReducer,

});

export default connect(mapStateToProps, mapDispatchToProps)(LatestNews);