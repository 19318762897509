import React from 'react';
import PropTypes from "prop-types";

const StatisticsExplorerNavigator = (props) => {

    let l = `/Stats/${props.sport}/${props.year}/${props.division}?v=Leaders`; 
    let first = 'Statistiques équipes';
    let link = <a className="stats-navigator__link" href={l}>Records</a>
    if (props.selected ==='records')
    {
        first = 'Records';
        l = `/Stats/${props.sport}/${props.year}/${props.division}/Teams`;
        link = <a className="stats-navigator__link stats-navigator__link--hidden" href={l}>Statistiques par équipe</a>
    }
    return (
        <div className="stats-navigator__container">
            <h3 className="stats-navigator__title">
                <ul className="stats-navigator__title-text">
                    <li className="stats-navigator__separator">{props.sport}</li>
                    <li className="stats-navigator__separator">{props.division}</li>
                    <li className="stats-navigator__separator">{props.year}</li>
                    <li className="stats-navigator__separator">{first}</li>
                </ul>
                {link}
            </h3>
        </div>

    );
}

StatisticsExplorerNavigator.propTypes = {
    sport: PropTypes.string,
    division: PropTypes.string,
    year: PropTypes.string,
    selected: PropTypes.string
};

export default StatisticsExplorerNavigator;