import React from 'react';
import PropTypes from "prop-types";
import FeedDate from './FeedDate';
import YouTubeIcon from '@mui/icons-material/YouTube';

const VideoFeedSummary = (props) => {

    /* If the item is not defined or no data (means person), don't display it */
    if (props.item === undefined)
    {
        return '';
    }

    const dt = new Date(props.item.publicationDate);
    const current = new Date();
    const seconds = (current - dt) / 1000;
    let youtubeAddress = props.autoplay ? `https://www.youtube.com/embed/${props.item.mediaId}?autoplay=1&color=white` : `https://www.youtube.com/embed/${props.item.mediaId}}?autoplay=0&color=white`;
    let showPlayer = seconds < 172801;
    let ytid = `ytv${props.item.mediaId}`;
    let yt = showPlayer ? <div className="youtube-container"><iframe title="youtube" id={ytid} type="text/html" width="720" height="405" src={youtubeAddress} frameborder="0" allowfullscreen /></div> :
    <a href={props.item.link} target="_blank" rel="noreferrer" className="news-feed__title"><img src={props.item.externalImageAddress} alt="youtube" className="news-feed__youtube-image"/></a>
    ;
    

    return <article className="news-feed">
            <div className="news-feed__source">
                <div className="news-feed__organization">{props.item.team.name} {props.item.team.surName}</div>
                <FeedDate dateClassName="news-feed__date" date={props.item.publicationDate} />
            </div>
            <div className="news-feed__data">
                <div className="news-feed__information news-feed__information--video">
                    <a href={props.item.link} target="_blank" rel="noreferrer" className="news-feed__title"><YouTubeIcon fontSize="large" className="news-feed__icon"/> {props.item.title}</a>
                    <div className="news-feed__summary news-feed__summary--video">
                        {yt}
                    </div>                   
                </div>
            </div>    
        </article>
}

/* properties : item and index are required */
VideoFeedSummary.propTypes = {
    item: PropTypes.object
};

export default VideoFeedSummary;