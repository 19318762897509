import React from 'react';
import PropTypes from "prop-types";
import { Config } from '../../config/index';

const FeedMedia = (props) => {

    const text = () => {
        if (props.team !== undefined && props.team !== null && props.team.shortCut !== undefined && props.team.shortCut !== null && props.team.shortCut !== '')
        {
            return `${props.team.name} ${props.team.surName}`;
        }
        return 'France Baseball';
    };

    const imgAddress = () => {
        if (props.url !== undefined && props.url !== null && props.url !== ''){
            return props.url;
        }
        else
        {
            if (props.team !== undefined && props.team !== null && props.team.shortCut !== undefined && props.team.shortCut !== null && props.team.shortCut !== '')
            {
                return `${Config.cdn}/img/org/${props.team.shortCut}/news.png`;
            }
            return `${Config.cdn}/img/org/DEFAULT/news.png`;
        }
    };

    return (
        <a href={props.link} rel="noreferrer" target="_blank" className={props.linkClass}>
            <img src={imgAddress()} alt={text()} className={props.imgClass} />
         </a>
    );
}

/* properties :  */
FeedMedia.propTypes = {
    team: PropTypes.object,
    url: PropTypes.string,
    link: PropTypes.string,
    linkClass: PropTypes.string,
    imgClass: PropTypes.string,
};

export default FeedMedia;