import React, {Component} from 'react';
import { connect } from "react-redux"; 
import { loadLatestCampaignsApi } from "../../actions/campaignsActions";
import Skeleton from '@mui/material/Skeleton';
import Carousel from 'nuka-carousel'
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { DefaultTheme } from '../../config/colors';
import CampaignItem from './CampaignItem';
import DefaultCampaign from './DefaultCampaign';

class LatestCampaigns extends Component {
    constructor(props) {
        super(props)
        // load the data from the initFn set in mapDispatchToProps
        this.props.initFn();
      }

    render() {
        if (!this.props.campaignsReducer.loaded){
            // Data not yet loaded
            return (
                <div className="latest-campaigns latest-campaigns--skeleton">
                    <Skeleton variant="rectangular" height={280}/>
                </div>
            );
        }

        let campaigns;
        let controlsConfig = {
            nextButtonText: <ChevronRight />,
            prevButtonText: <ChevronLeft />,
            pagingDotsStyle: {
              fill: DefaultTheme.pageBackground.default
            }
          };

        if(this.props.campaignsReducer.data === undefined || this.props.campaignsReducer.data === null || this.props.campaignsReducer.data.length === 0)
        {
            campaigns = <DefaultCampaign />
        }
        else
        {
            campaigns = this.props.campaignsReducer.data.map(function(item, index){ return <CampaignItem key={index} item={item}/>;});
        }
        
        return ( 
            <div className="latest-campaigns">
            <Carousel autoplay="true" autoplayReverse="true" pauseOnHover="true" initialSlideHeight={200} defaultControlsConfig={controlsConfig}>
                  {campaigns}
            </Carousel>
            </div>
        )
    }

}


const mapDispatchToProps = dispatch => {
    return {
        // Put in the props the dispatch to load the data
        initFn: () => dispatch(loadLatestCampaignsApi()),
    }
}

const mapStateToProps = state => ({
    ///...state,
    campaignsReducer: state.campaignsReducer,

});

export default connect(mapStateToProps, mapDispatchToProps)(LatestCampaigns);