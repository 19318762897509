import React from 'react';
import PropTypes from "prop-types";
import StatisticsSummaryTeam from './StatisticsSummaryTeam';
import { Config } from '../../config';
import Avatar from '@mui/material/Avatar';
import { Skeleton, Box } from '@mui/material';

const StatisticsLeadersItem = (props) => {


    
    let team = '';
    let teamName = ''
    let avatar = `${Config.cdn}/img/org/DEFAULT/news.png`;

    if (props.item === undefined)
    {
        return (
            <div className="stats-leader-item">
                <Box sx={{ width: { xs:24, sm: 24, md: 36, lg:56, xl:56}, height: { xs:24, sm: 24, md: 36, lg:56, xl:56} }} >
                    <Skeleton animation="wave" variant="circular" sx={{ width: { xs:24, sm: 24, md: 36, lg:56, xl:56}, height: { xs:24, sm: 24, md: 36, lg:56, xl:56} }} />
                </Box>
            <div className="stats-leader-item__data">
                <Skeleton animation="wave" sx={{width:'100%', heigth:{ xs:24, sm: 24, md: 36, lg:56, xl:56} }} />   
            </div>
        </div>);
    }
    if (props.item.team !== null && props.item.team !== undefined && props.item.team.shortCut !== undefined){
        team = <StatisticsSummaryTeam name={props.item.team.name} surName={props.item.team.surName} shortCut={props.item.team.shortCut} />
        avatar = `${Config.cdn}/img/org/${props.item.team.shortCut}/news.png`;
        teamName = `${props.item.team.name} ${props.item.team.surName}`;
    }

    


    return (
        <div className="stats-leader-item">
            <Avatar
                    className="stats-summary-item__avatar stats-leader-item__avatar"
                    alt={teamName}
                    src={avatar}
                    sx={{ width: { xs:24, sm: 24, md: 36, lg:56, xl:56}, height: { xs:24, sm: 24, md: 36, lg:56, xl:56} }}/>
            <div className="stats-leader-item__data">
                <div className="stats-leader-item__rank">
                    {props.item.rank}
                </div>
                <div className="stats-leader-item__identity">
                    <div className="stats-leader-item__player">{props.item.firstName} {props.item.lastName}</div>
                    {team}
                </div>
            </div>
            <div className="stats-leader-item__figures">
                <div className="stats-leader-item__value">{props.item.value}</div>
            </div>
        </div>
    );
}

StatisticsLeadersItem.propTypes = {
    item: PropTypes.object
};

export default StatisticsLeadersItem;