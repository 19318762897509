import React from 'react';
import PropTypes from "prop-types";
import LiveTvIcon from '@mui/icons-material/LiveTv';

const YoutubeLive = (props) => {

    /* If the item is not defined or no data (means person), don't display it */
    if (props.item === undefined)
    {
        return '';
    }


    let youtubeAddress = props.autoplay ? `https://www.youtube.com/embed/${props.item.mediaId}?autoplay=1&color=white` : `https://www.youtube.com/embed/${props.item.mediaId}?color=white`;
    let ytid = `ytplayer${props.item.mediaId}`;
    let yt = <div className="youtube-container"><iframe title="youtube" id={ytid} type="text/html" width="720" height="405" src={youtubeAddress} frameborder="0" allowfullscreen /></div>;
    

    return <article className="news-feed">
            <div className="news-feed__data">
                <div className="news-feed__information news-feed__information--video">
                    <a href={props.item.link} target="_blank" rel="noreferrer" className="news-feed__title"><LiveTvIcon fontSize="large" className="news-feed__icon news-feed__icon--live-tv" /> {props.item.title}</a>
                    <div className="news-feed__summary news-feed__summary--video">
                        {yt}
                    </div>                   
                </div>
            </div>    
        </article>
}

/* properties : item and index are required */
YoutubeLive.propTypes = {
    item: PropTypes.object,
    autoplay: PropTypes.bool
};

export default YoutubeLive;