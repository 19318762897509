import React from 'react';
import { Config } from '../../config/index';

const BaseballTv = (props) => {

    const appStoreAlternate = `${Config.cdn}/img/stores/AppStore.svg`;
    const android = `${Config.cdn}/img/stores/google-play-badge.png`;
    const logo = `${Config.cdn}/img/org/BBFTV/baseballTv.png`;
    return (
        <section className="baseballtv__container">
            <div className="baseballtv__image"><img className="baseballtv__logo" src={logo} alt="baseballTv"></img></div>
            <div className="baseballtv__caption">Toute l'actualité du Baseball, Softball, Baseball5 en France sur l'application Baseball TV France</div>
            <ul className="baseballtv__stores">
                <li className="baseballtv__store">
                    <a href="https://apps.apple.com/ci/app/baseball-tv-france/id1567670423" rel="noreferrer" target="_blank" className="baseballtv__link">
                        <img className="baseballtv__app-store" src={appStoreAlternate} alt="Téléchargez sur l'App Store" />
                    </a>
                </li>
                <li className="baseballtv__store">
                    <a href="https://play.google.com/store/apps/details?id=com.crowdaa.app.baseballtvfrance&gl=FR" rel="noreferrer" target="_blank" className="baseballtv__link">
                        <img src={android} alt="Téléchargez sur Google Play" className="baseballtv__google-play"/>
                    </a>
                </li>
            </ul>
        </section>
    );
}

export default BaseballTv;