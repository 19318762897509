import React, {Component} from 'react';
import { connect } from "react-redux"; 
import PropTypes from "prop-types";

import Skeleton from '@mui/material/Skeleton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


import { loadStatisticsSummaryApi } from "../../actions/statisticsSummaryActions";
import StatisticsSummaryItem from "./StatisticsSummaryItem";
import StatisticsSummaryPerson from './StatisticsSummaryPerson';

class StatisticsSummary extends Component {
    constructor(props) {
        super(props)
        // load the data from the initFn set in mapDispatchToProps
        this.props.initFn(this.props.sport, this.props.league, this.props.year);
        this.state = { selectedIndex: 0};
      }



    render() {
        if (!this.props.statisticsSummaryReducer.loaded){
            // Data not yet loaded
            const skeletons = Array.apply(null, Array(8)).map(n=> <StatisticsSummaryPerson key={n} loading={true}/>);

            return (
            <Box sx={{width: '100%' }} className="fb-standard-box">
                <Skeleton animation="wave" />
                {skeletons}
            </Box>);
        }

        if (this.props.statisticsSummaryReducer.data === undefined || this.props.statisticsSummaryReducer.data === null)
        {
            return '';
        }
        
        const a11yProps =(index) =>{
            return {
              id: `simple-tab-${index}`,
              'aria-controls': `simple-tabpanel-${index}`,
            };
        }

        const handleChange = (event, newValue) => {
            this.setState({selectedIndex: newValue});
        };

        // Data is loaded now
        const s = this.props.sport;
        const l = this.props.league;
        const y = this.props.statisticsSummaryReducer.data.year;

        var battingItems = this.props.statisticsSummaryReducer.data.batting.map(function(item, index){ return <StatisticsSummaryItem key={index} item={item} sport={s} league={l} year={y} />;});
        var pitchingItems = this.props.statisticsSummaryReducer.data.pitching.map(function(item, index){ return <StatisticsSummaryItem key={index} item={item} sport={s} league={l} year={y} />;});
        return (

                <Box sx={{width: '100%' }} className="fb-standard-box">
                    <h3 className="fb-standard-box__title"><a href="/Stats/Baseball/2022/D1" className="fb-standard-box__title-link">Leaders {this.props.statisticsSummaryReducer.data.league} {this.props.statisticsSummaryReducer.data.sportType} {this.props.statisticsSummaryReducer.data.year}</a></h3>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={this.state.selectedIndex} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Batting" {...a11yProps(0)} />
                            <Tab label="Pitching" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    
                    <div
                        role="tabpanel"
                        hidden={this.state.selectedIndex !== 0}
                        id="simple-tabpanel-0"
                        aria-labelledby="simple-tab-0">
                        {this.state.selectedIndex === 0 && (
                            <div className="stats-summary">
                                {battingItems}
                            </div>
                        )}
                     </div>
                    
                     <div
                        role="tabpanel"
                        hidden={this.state.selectedIndex !== 1}
                        id="simple-tabpanel-1"
                        aria-labelledby="simple-tab-1">
                        {this.state.selectedIndex === 1 && (
                            <div className="stats-summary">
                                {pitchingItems}
                            </div>
                        )}
                     </div>
                </Box>
   
            );
        }

}

const mapDispatchToProps = dispatch => {
    return {
        // Put in the props the dispatch to load the data
        initFn: (sport, league, year) => dispatch(loadStatisticsSummaryApi(sport, league, year)),
    }
}

const mapStateToProps = state => ({
    ///...state,
    statisticsSummaryReducer: state.statisticsSummaryReducer,

});

StatisticsSummary.propTypes = {
    sport: PropTypes.string.isRequired, 
    league: PropTypes.string.isRequired, 
    year: PropTypes.string  
  };

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsSummary);