import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';

import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk';


import combineReducers from './reducers/index'

import App from './App';
// import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const store = createStore(combineReducers, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <App /> 
        </BrowserRouter>
    </Provider>
    ,
  rootElement);

// registerServiceWorker();

