import * as types from './actionsTypes';
import { Config } from '../config/index';
import axios from 'axios';



export const loadLatestBlogsNewsApi = () => async (dispatch) => {
    try {
        const payload = await axios.get(Config.api + '/News/LatestBlogsNews');
        dispatch({ type: types.LATEST_BLOGS_NEWS, payload: payload.data });
    } catch (error) {
        dispatch({ type: types.LATEST_BLOGS_NEWS_ERROR, payload: null });
    }
};


