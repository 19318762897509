import * as types from './actionsTypes';
import { Config } from '../config/index';
import axios from 'axios';



export const loadLatestNewsApi = () => async (dispatch) => {
    console.log("Execution de newsActions.loadLatestNewsApi") ;
    try {
        const payload = await axios.get(Config.api + '/News/Latest');
        dispatch({ type: types.LATEST_NEWS, payload: payload.data });
    } catch (error) {
        dispatch({ type: types.LATEST_NEWS_ERROR });
    }
};


