import React, {Component} from 'react';
import { connect } from "react-redux"; 
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Skeleton from '@mui/material/Skeleton';
import LoadError from '../error/LoadError';

import { loadStatisticsExplorerLeadersBaseData, loadLeadersData, initializeExplorer, saveLeadersList } from "../../actions/statisticsExplorerActions";

import StatisticsExplorerNavigator from './StatisticsExplorerNavigator';
import StatisticsLeadersItem from './StatisticsLeadersItem';

/**
 * StatisticsExplorer Component to discover a Sport, year and Division statistics
 */
class StatisticsExplorer extends Component {
    loadLeadersData = (category, typeOfStatistics) => {
        // Identifying the type of Data
        if (category !== null && typeOfStatistics !== null)
        {
            this.props.getLeadersFn(this.state.sport, this.state.year, this.state.division, category, typeOfStatistics);
            return;
        }
        switch(this.props.statisticsExplorerReducer.leadersCategory)
        {
            case 'Batting':
                typeOfStatistics = this.props.statisticsExplorerReducer.leadersTypeOfStatistics.batting;
                break;
            case 'Pitching':
                typeOfStatistics = this.props.statisticsExplorerReducer.leadersTypeOfStatistics.pitching;
                break;
            case 'Catching':
                typeOfStatistics = this.props.statisticsExplorerReducer.leadersTypeOfStatistics.catching;
                break;
            case 'Fielding':
                typeOfStatistics = this.props.statisticsExplorerReducer.leadersTypeOfStatistics.fielding;
                break;
            default:
                return;

        }
        this.props.getLeadersFn(this.state.sport, this.state.year, this.state.division, this.props.statisticsExplorerReducer.leadersCategory, typeOfStatistics);
    };
    /**
     * new instance of StatisticsExplorer
     * @param {object} props 
     */
    constructor(props) {
        super(props)

        const queryParams = new URLSearchParams(window.location.search); 
        this.state = { 
            sport: this.props.sport, // Baseball, Softball...
            year: this.props.year,  // 2021, 2022...
            division: this.props.division,  // D1, D2...
            data: null
        };

        // Getting the list of categories for the leaders dropdown lists
        this.props.initLeadersFn().then((v)=> {
            this.props.initializeExplorerFn(queryParams).then((x)=>{
                this.loadLeadersData(null, null);
            });
        })
    }

    /**
     * Rendering the component
     * @returns the HTML
     */
    render() {
        let b = <Skeleton animation="wave" sx={{height:'80px', width:'20%'}}/>;
        let p =<Skeleton animation="wave" sx={{height:'80px', width:'20%'}}/>;
        let f = <Skeleton animation="wave" sx={{height:'80px', width:'20%'}}/>;
        let c = <Skeleton animation="wave" sx={{height:'80px', width:'20%'}}/>;
        let x = Array.apply(null, Array(8)).map(n=> <StatisticsLeadersItem key={n} />);
        x = <div className="stats-explorer__records-results">{x}</div>;

        if (this.props.statisticsExplorerReducer.leadersForm !== null) {
            b = this.renderLeaderList("leader-list-batting", "Batting", this.props.statisticsExplorerReducer.leadersForm.batting, this.props.statisticsExplorerReducer.leadersTypeOfStatistics.batting, 206);
            p = this.renderLeaderList("leader-list-pitching", "Pitching", this.props.statisticsExplorerReducer.leadersForm.pitching, this.props.statisticsExplorerReducer.leadersTypeOfStatistics.pitching, 251);
            f = this.renderLeaderList("leader-list-fielding", "Fielding", this.props.statisticsExplorerReducer.leadersForm.fielding, this.props.statisticsExplorerReducer.leadersTypeOfStatistics.fielding, 178);
            c = this.renderLeaderList("leader-list-catching", "Catching", this.props.statisticsExplorerReducer.leadersForm.catching, this.props.statisticsExplorerReducer.leadersTypeOfStatistics.catching, 191);
        }

        if (this.props.statisticsExplorerReducer.loaded)
        {
            if (this.props.statisticsExplorerReducer.hasError)
            {
                x = <LoadError />;
            }
            else if (this.props.statisticsExplorerReducer.leadersData !== null)
            {
                const abbr = this.props.statisticsExplorerReducer.leadersData.item.abbrevation;
                let it = this.props.statisticsExplorerReducer.leadersData.item.data.map(function(item, index){ return <StatisticsLeadersItem key={index} item={item} abbrevation={abbr}/>;});
                x = <div className="stats-explorer__records-results">
                        <h4 className="stats-explorer__records-title">{this.props.statisticsExplorerReducer.leadersData.item.name}</h4>
                        <div>
                            {it}
                        </div>
                    </div>;
            }
            else
            {
                x = <div>Aucune donnée disponible</div>;
            }
        }

        if (this.props.statisticsExplorerReducer.leadersForm !== null){
            return (
                <div className="fb-site">
                    <div className="fb-site__center stats-explorer__container">
                        <div className="fb-standard-box">
                            <StatisticsExplorerNavigator sport={this.state.sport} division={this.state.division} year={this.state.year} selected="records" />
                            <div className="stats-explorer__filters">{b}{p}{c}{f}</div>
                            {x}
                        </div>
                    </div>
                </div>);
        }
        return (<div>rien</div>);

    }

    getLeaderKey = () => {

        switch(this.props.statisticsExplorerReducer.leadersCategory){
            case 'Batting':
                return  `${this.props.statisticsExplorerReducer.leadersCategory}_${this.props.statisticsExplorerReducer.leadersTypeOfStatistics.batting}`;
            case 'Pitching':
                return  `${this.props.statisticsExplorerReducer.leadersCategory}_${this.props.statisticsExplorerReducer.leadersTypeOfStatistics.pitching}`;
            case 'Catching':
                return  `${this.props.statisticsExplorerReducer.leadersCategory}_${this.props.statisticsExplorerReducer.leadersTypeOfStatistics.catching}`;
            case 'Fielding':
                return  `${this.props.statisticsExplorerReducer.leadersCategory}_${this.props.statisticsExplorerReducer.leadersTypeOfStatistics.fielding}`;
            default:
                return '';
        }
    };


    /**
     * Handler executed while changing any list of the Leaders selectors
     * @param {string} value The value of the dropdown list selected
     * @param {string} id The Id of the dropdown list
     */
    handleLeadersListChange = (value, id) =>{

        let t = '';
        let v = '';

        switch(id)
        {
            case 'leader-list-batting':
                t = 'Batting';
                v = value;
                break;
            case 'leader-list-pitching':
                t = 'Pitching';
                v = value;
                break;
            case 'leader-list-catching':
                t = 'Catching';
                v = value;
                break;
            case 'leader-list-fielding':
                t = 'Fielding';
                v = value;
                break;
            default:
                t = 'Batting';
                v = 'BattingAvg';
                break;
        }
        this.props.saveLeadersListFn(t, v);
        this.props.history.push({search: "?" + new URLSearchParams({v:'Leaders', s:v, c:t}).toString()});
        this.loadLeadersData(t,v);
    };

    /**
     * Building a Leaders List selector
     * @param {string} id Identifier of the list
     * @param {string} title Title of the list
     * @param {object} data The items of the list
     * @param {string} defaultValue The default value to set (Empty string if no selection)
     * @returns a FormControl with the dropdown list
     */
    renderLeaderList(id, title, data, defaultValue, width) {
        const items = data.map(x=> <MenuItem value={x.typeOfStatistics}>{x.name}</MenuItem>);

        return <FormControl sx={{ m: 1, minWidth: width }}>
            <InputLabel id={id}>{title}</InputLabel>
            <Select labelId={id} value={defaultValue} label={title} onChange={e=> this.handleLeadersListChange(e.target.value, id)}>
                {items}
            </Select>
            </FormControl>
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // Put in the props the dispatch to load the data
        initLeadersFn: () => {
            return dispatch(loadStatisticsExplorerLeadersBaseData()); 
        },
        initializeExplorerFn : (queryParams) => {
            return dispatch(initializeExplorer(queryParams));
        },
        saveLeadersListFn : (cat, type) => {
            return dispatch(saveLeadersList({category:cat, typeOfStatistics:type}));
        },
        /**
         * Getting the statistics data for the given sport, year, division and type of statistics
         * @param {string} sport 
         * @param {integer} year 
         * @param {string} division 
         * @param {string} category 
         * @param {string} typeOfStatistics 
         * @returns 
         */
        getLeadersFn: (sport, year, division, category, typeOfStatistics) =>{
            return dispatch(loadLeadersData(sport, year, division, category, typeOfStatistics));
        },
    }
}

const mapStateToProps = state => ({
    ///...state,
    statisticsExplorerReducer: state.statisticsExplorerReducer,

});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(StatisticsExplorer);