import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';
import MenuBookItem from '@mui/icons-material/MenuBook';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

import { makeStyles } from '@mui/styles';
import { Config } from '../../config';


// import { useHistory } from 'react-router';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const useStyles = makeStyles({
    logo: {
      maxHeight:60,
      marginRight:'10px'
    },
    barLink: {
      flexGrow: 1,
    }
  });

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function Navigation(props) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

  const classes = useStyles();
  const url = `${Config.cdn}/img/site/logo-text.png`;

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, right:open });
  };

  const history = useHistory();
  
  const linkBlogs =() =>{
    history.push("/Blogs");
  }
  const linkHome =() =>{
    history.push("/");
  }
  const linkTwitter =() =>{
    window.open(Config.social.twitter);
  }
  const linkFacebook =() =>{
    window.open(Config.social.facebook);
  }
  const linkInstagram =() =>{
    window.open(Config.social.instagram);
  }
  const linkD1Records =() =>{
    history.push("/Stats/Baseball/2022/D1");
  }

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button key="home" onClick={linkHome}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Accueil" />
        </ListItem>
        <ListItem button key="blogs" onClick={linkBlogs}>
          <ListItemIcon>
            <MenuBookItem />
          </ListItemIcon>
          <ListItemText primary="Blogs" />
        </ListItem>
        <ListItem button key="recordsD1" onClick={linkD1Records}>
          <ListItemIcon>
            <AnalyticsIcon />
          </ListItemIcon>
          <ListItemText primary="D1 - Leaders" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key="twitter" onClick={linkTwitter}>
            <ListItemIcon>
              <TwitterIcon />
            </ListItemIcon>
            <ListItemText primary="Twitter" />
          </ListItem>
          <ListItem button key="facebook" onClick={linkFacebook}>
            <ListItemIcon>
              <FacebookIcon />
            </ListItemIcon>
            <ListItemText primary="Facebook" />
          </ListItem>
          <ListItem button key="home" onClick={linkInstagram}>
            <ListItemIcon>
              <InstagramIcon />
            </ListItemIcon>
            <ListItemText primary="Instagram" />
          </ListItem>
      </List>
    </Box>
  );


  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar color="primary">
        <Toolbar>
          <a href="/" title="accueil" className="fb-navigation__home-link"><img src={url} alt="france baseball" className={classes.logo} /></a>
          <Typography variant="h7" component="div" sx={{ flexGrow: 1 }} className={classes.barLink} >
            <a href="/Blogs" className="fb-navigation__link">Blogs</a>
            <a href="/Stats/Baseball/2022/D1" className="fb-navigation__link">Statistiques D1</a>
          </Typography>

          <React.Fragment key="right">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }} onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
      <SwipeableDrawer
            anchor="right"
            open={state.right}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
          </React.Fragment>


        </Toolbar>
        </AppBar>

      </HideOnScroll>
      <Toolbar />

    </React.Fragment>
  );
}