import { combineReducers } from 'redux';
import statisticsSummaryReducer from './statisticsSummaryReducer';
import newsReducer from './newsReducer';
import campaignsReducer from './campaignsReducer';
import sportsEventsReducer from './sportsEventsReducer';
import blogsReducer from './blogsReducer';
import statisticsExplorerReducer from './statisticsExplorerReducer';

export default combineReducers({
    statisticsSummaryReducer: statisticsSummaryReducer,
    statisticsExplorerReducer: statisticsExplorerReducer,
    newsReducer: newsReducer,
    campaignsReducer: campaignsReducer,
    sportsEventsReducer:sportsEventsReducer,
    blogsReducer : blogsReducer
});