import React, { Component } from 'react';
import StatisticsSummary  from '../components/statistics/StatisticsSummary';
import LatestNews from '../components/news/LatestNews';
import LatestCampaigns from './campaigns/LatestCampaigns';
import SportsEvents from './sportsEvents/SportsEvents';
import TheFoulPole from './blogs/TheFoulPole';
import BaseballTv from './partners/BaseballTv';
export class Home extends Component {

  componentDidMount(){
    document.title = "France Baseball";
  }
  static displayName = Home.name;

  render () {
    
    return (
      <div className="fb-site">
        <div className="fb-site__left">
          <SportsEvents />
        </div>
        <div className="fb-site__center">
          <LatestCampaigns />
          <LatestNews />
        </div>
        <div className="fb-site__right">
          <TheFoulPole />
          <BaseballTv />
          <StatisticsSummary sport="Baseball" league="D1" year="" />
        </div>
    </div>

    );
  }
}
