import * as types from "../actions/actionsTypes";

// Reducer named blogsReducer
const initialState = {
    loaded:false,
    error: false,
    data: null
};

export default function blogsReducer(state = initialState, action) {
    switch (action.type) {
        case types.LATEST_BLOGS_NEWS:
            return {...state, loaded:true, data: action.payload};
            case types.LATEST_BLOGS_NEWS_ERROR:
                return {...state, loaded:true, error:true, data: null};       
        default:
            return state;
    }
};