import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { DefaultTheme } from './config/colors';

import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Blogs } from './components/Blogs';
import StatisticsExplorer from './components/statistics/StatisticsExplorer';

import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: DefaultTheme.primary.main
    },
    background:
    { 
      default: DefaultTheme.pageBackground.default
    },
    text: {
      primary: DefaultTheme.text.primary
    } 
  }
});

export default class App extends Component {
  static displayName = App.name;


  render () {
    return (
      <ThemeProvider theme={theme}>
        <Layout>
          <Switch>
            <Route exact={true} path='/' component={Home} />
            <Route exact={true} path='/index.html'>
              <Redirect to='/'/>
            </Route>
            <Route exact={true} path='/Blogs' component={Blogs} /> 
            <Route exact={true} path='/Stats/:sport(\w+)/:year(\d+)/:division(\w+)' render={(props=>(<StatisticsExplorer sport={props.match.params.sport} year={props.match.params.year} division={props.match.params.division} />))} />           

            <Route path='*' component={Home} />
          </Switch>
        </Layout>
      </ThemeProvider>
    );
  }
}
