import * as types from './actionsTypes';
import { Config } from '../config/index';
import axios from 'axios';



export const loadSportsEventsApi = () => async (dispatch) => {
    try {
        const payload = await axios.get(Config.api + '/SportsEvents');
        dispatch({ type: types.SPORTS_EVENTS, payload: payload.data });
        if (payload.data.dailyEvents.length > 0){
            let f =  payload.data.dailyEvents[0].liveEvents.filter(f=>f.onProgress && f.typeOfLiveEvent === 'YoutubeLive');
            if (f.length >= 0){
                dispatch({type: types.YOUTUBE_LIVE_ON_PROGRESS, payload: f});
            }
            
        }
        
    } catch (error) {
        dispatch({ type: types.SPORTS_EVENTS, payload: null });
    }
};


