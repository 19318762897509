import React from 'react';
import PropTypes from "prop-types";
import StatisticsSummaryTeam from './StatisticsSummaryTeam';
import { Config } from '../../config';
import Avatar from '@mui/material/Avatar';
import { Skeleton, Box } from '@mui/material';

const StatisticsSummaryPerson = (props) => {

    if (props.loading){
        return (
            
            <div className="stats-summary-item">
                <Box  sx={{ width: { xs:24, sm: 24, md: 36, lg:56, xl:56}, height: { xs:24, sm: 24, md: 36, lg:56, xl:56} }}>
                    <Skeleton animation="wave" variant="circular" sx={{ width: { xs:24, sm: 24, md: 36, lg:56, xl:56}, height: { xs:24, sm: 24, md: 36, lg:56, xl:56} }} />
                </Box>
                <div className="stats-summary-item__data stats-summary-item__data--no-border">
                    <Box className="stats-summary-item__identity" sx={{ width: { xs:80, sm: 80, md: 100, lg:200, xl:200}}} >
                        <div className="stats-summary-item__category"><Skeleton animation="wave" height={20} width="50%" /></div>
                        <div className="stats-summary-item__player"><Skeleton animation="wave" height={24} width="80%" /></div>
                    </Box>
                    <Box className="stats-summary-item__figures" sx={{ width: { xs:40, sm: 40, md: 60, lg:70, xl:70}}}>
                        <div className="stats-summary-item__value"><Skeleton animation="wave" height={24} width="40%" /></div>
                        <div className="stats-summary-item__abbreviation"><Skeleton animation="wave" height={18} width="40%" /></div>
                    </Box>
                </div>
            </div>
        );
    }

    let team = '';
    let teamName = ''
    let avatar = `${Config.cdn}/img/org/DEFAULT/news.png`;
    if (props.personItem.team !== null && props.personItem.team !== undefined && props.personItem.team.shortCut !== undefined){
        team = <StatisticsSummaryTeam name={props.personItem.team.name} surName={props.personItem.team.surName} shortCut={props.personItem.team.shortCut} />
        avatar = `${Config.cdn}/img/org/${props.personItem.team.shortCut}/news.png`;
        teamName = `${props.personItem.team.name} ${props.personItem.team.surName}`;
    }
    

    let link = `/Stats/${props.sport}/${props.year}/${props.league}?v=Leaders&c=${props.category}&s=${props.typeOfStatistics}`;

    return (
        <div className="stats-summary-item">
            <Avatar
                    className="stats-summary-item__avatar"
                    alt={teamName}
                    src={avatar}
                    sx={{ width: { xs:24, sm: 24, md: 36, lg:56, xl:56}, height: { xs:24, sm: 24, md: 36, lg:56, xl:56} }}/>
            <div className="stats-summary-item__data">
                <div className="stats-summary-item__identity">
                    <div className="stats-summary-item__category"><a className="stats-summary-item__category-link" href={link}>{props.categoryName}</a></div>
                    <div className="stats-summary-item__player">{props.personItem.firstName} {props.personItem.lastName}</div>
                    {team}
                </div>
                <div className="stats-summary-item__figures">
                    <div className="stats-summary-item__value">{props.personItem.value}</div>
                    <div className="stats-summary-item__abbreviation">{props.abbreviation}</div>
                </div>
            </div>
        </div>
    );
}

StatisticsSummaryPerson.propTypes = {
    personItem: PropTypes.object
};

export default StatisticsSummaryPerson;