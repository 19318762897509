import React from 'react';
import PropTypes from "prop-types";
import YouTubeIcon from '@mui/icons-material/YouTube';

const YoutubeLiveEvent = (props) => {

    if (props.item === undefined)
    {
        return '';
    }

    const leadingZero = (num) => `0${num}`.slice(-2);
    const formatTime = (date) => [date.getHours(), date.getMinutes()].map(leadingZero).join(':');
    let time = formatTime(new Date(props.item.localStartDateTime));
    let progress = props.item.onProgress ? 'youtube-live__dot youtube-live__dot--on-progress' : 'youtube-live__dot';
    
    const content = () => (<div className="daily-event__item youtube-live">
    <div className="youtube-live__progress">
        <span className={progress}></span>
    </div>
    <div className="youtube-live__title">{props.item.title}</div>
    <div className="youtube-live__info">
        <div className="youtube-live__time">{time}</div>
        <YouTubeIcon className="youtube-live__icon" fontSize="large" />
    </div>
</div>);

    if (props.item.onProgress)
    {
        return (
            <a href={props.item.link} target="_blank" rel="noreferrer" className="youtube-live__link">
                {content()}
            </a>
    );
    }

    return (
            <React.Fragment>
                {content()}
            </React.Fragment>
    );
}

YoutubeLiveEvent.propTypes = {
    item: PropTypes.object
};

export default YoutubeLiveEvent;