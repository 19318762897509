export const STATISTICS_SUMMARY = "STATISTICS_SUMMARY";

export const STATISTICS_BASE_LEADER_DATA = "STATISTICS_BASE_LEADER_DATA";
export const STATISTICS_LEADERS_SET_SEARCH = "STATISTICS_LEADERS_SET_SEARCH";

export const STATISTICS_LEADERS_KEY_DATA_LOADING = "STATISTICS_LEADERS_KEY_DATA_LOADING";
export const STATISTICS_LEADERS_KEY_DATA_LOADING_ERROR = "STATISTICS_LEADERS_KEY_DATA_LOADING_ERROR";
export const STATISTICS_LEADERS_KEY_DATA = "STATISTICS_LEADERS_KEY_DATA";

export const LATEST_NEWS = "LATEST_NEWS";
export const LATEST_NEWS_ERROR = "LATEST_NEWS_ERROR";

export const LATEST_CAMPAIGNS = "LATEST_CAMPAIGNS";
export const SPORTS_EVENTS = "SPORTS_EVENTS";
export const YOUTUBE_LIVE_ON_PROGRESS = "YOUTUBE_LIVE_ON_PROGRESS";

export const LATEST_BLOGS_NEWS = "LATEST_BLOGS_NEWS";
export const LATEST_BLOGS_NEWS_ERROR = "LATEST_BLOGS_NEWS_ERROR";
