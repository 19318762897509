module.exports = global.devConfig = {
    cdns: 'http://cdn-dev.francebaseball.com/v4',
    cdn: 'https://localhost:44392/cdn/v4',
    api: 'https://localhost:44304',
    internalApi: 'https://localhost:44392',
    social: {
        facebook:'https://facebook.com/FranceBaseball',
        instagram:'https://instagram.com/francebaseball',
        twitter:'https://twitter.com/francebaseball'
    }
};