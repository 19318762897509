import React from 'react';
import PropTypes from "prop-types";

const StatisticsSummaryTeam = (props) => {
    return (
        <div className="stats-summary-team">
            <div className="stats-summary-team__name">{props.name} {props.surName}</div>
            <div className="stats-summary-team__small-name">{props.shortCut}</div>
        </div>
    );
}

StatisticsSummaryTeam.propTypes = {
    name: PropTypes.string,
    surName: PropTypes.string,
    shortcut: PropTypes.string
};

export default StatisticsSummaryTeam;