import React from 'react';
import { Config } from '../../config/index';
import Box from '@mui/material/Box';

const TheFoulPole = (props) => {
    const illustration = `${Config.cdn}/img/site/TheFoulPole_Advert.png`;

    return (
        <Box sx={{width: '100%' }} className="fb-standard-box">
            <div className="latest-blogs-news__illustration">   
                <a href="/Blogs" title="Le coin des blogs"><img src={illustration} alt="Le coin des blogs" /></a> 
            </div>
            <div className="latest-blogs-news__description">
                <a href="/Blogs" className="latest-blogs-news__link" title="Le coin des blogs">Le coin des blogs</a>, les derniers podcasts et articles de bloggers du baseball français
            </div>
        </Box>
    );
}

export default TheFoulPole;