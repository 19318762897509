import React from 'react';

const LoadError = (props) => {
    return (
        <div className="error-loading">
            <div className="error-loading__eyes">+&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+</div>
            <div className="error-loading__nose">L</div>
            <div className="error-loading__mouth">--0-</div>
            <div className="error-loading__msg">Erreur de chargement</div>
        </div>
    );
}


export default LoadError;