import * as types from "../actions/actionsTypes";

// Reducer named sportsEventsReducer
const initialState = {
    loaded:false,
    data: null
};

export default function sportsEventsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SPORTS_EVENTS:
            return {...state, loaded:true, data: action.payload};
        default:
            return state;
    }
};