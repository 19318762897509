import React from 'react';
import { Config } from '../../config/index';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import Fab from '@mui/material/Fab';

const DefaultCampaign = (props) => {


    return (
        <div className="latest-campaigns__item latest-campaigns-default">
            <div className="latest-campaigns-default__logo">
                <img  className="latest-campaigns__img--fb" src={`${Config.cdn}/img/site/logo-300.png`} alt="FranceBaseball" />
            </div>
            <div className="latest-campaigns-default__socials">
                <a className="latest-campaigns-default__link" href={Config.social.twitter} target="_blank" rel="noreferrer" ><Fab aria-label="Twitter"><TwitterIcon fontSize="large" color="primary"/></Fab></a>
                <a className="latest-campaigns-default__link" href={Config.social.facebook} target="_blank" rel="noreferrer" ><Fab aria-label="Facebook"><FacebookIcon fontSize="large" color="primary" /></Fab></a>
                <a className="latest-campaigns-default__link" href={Config.social.instagram} target="_blank" rel="noreferrer" ><Fab aria-label="Instagram"><InstagramIcon fontSize="large" color="primary" /></Fab></a>
            </div>
            <div className="latest-campaigns-default__caption">
                Votre dose quotidienne de baseball français sur votre ordinateur, téléphone et réseaux sociaux
            </div>

        </div>
    );
}

export default DefaultCampaign;