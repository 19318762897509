import React, { Component} from 'react';
import  LatestBlogsNews  from './blogs/LatestBlogsNews';

export class Blogs extends Component {

  componentDidMount(){
    document.title = "The Foul Pole - Le coin des blogs"
  }
  render () {
    return (
      <div className="fb-site">
        <div className="fb-site__left">
        </div>
        <div className="fb-site__center">
          <LatestBlogsNews />
        </div>
        <div className="fb-site__right">

        </div>
    </div>

    );
  }
}
