import React, {Component} from 'react';
import { connect } from "react-redux"; 
import { loadLatestBlogsNewsApi } from "../../actions/blogsActions";
import { Config } from "../../config";

import LoadError from '../error/LoadError';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import FeedItem from '../news/FeedItem';

class LatestBlogsNews extends Component {
    constructor(props) {

        super(props)
        // load the data from the initFn set in mapDispatchToProps
        this.props.initFn();
      }
      
    render() {
        if (!this.props.blogsReducer.loaded){
            const skeletons = Array.apply(null, Array(5))
                .map(function(n, index) { 
                    return <div className="news-feed news-feed--skeleton" key={index}>
                                <Stack spacing={1}><Skeleton variant="text" height={40} />
                                    <Skeleton variant="rectangular" height={210}/>
                                </Stack>
                            </div>});

            return (
                <div>{skeletons}</div>
            );
        }

        if (this.props.blogsReducer.error)
        {
            return <LoadError />
        }
        
        let autoplay = false;

        const news = this.props.blogsReducer.data.map(function(item, index){ 
            if (item.typeOfFeed === 'Video' && !autoplay){
                let dt = new Date(item.publicationDate);
                let current = new Date();
                let seconds = (current - dt) / 1000;
                autoplay = seconds < 86401;
            }             
            return <FeedItem key={index} item={item} autoplay={autoplay}/>;});

        const illustration = `${Config.cdn}/img/site/TheFoulPole.png`;
        return (
            <section className="latest-blogs-news">
                <div className="latest-blogs-news__illustration">   
                    <img src={illustration} alt="Le coin des blogs" />
                </div>
                <div className="fb-standard-box latest-news">
                    {news}
                </div>
            </section>

            );
        }

}

const mapDispatchToProps = dispatch => {
    return {
        // Put in the props the dispatch to load the data
        initFn: () => dispatch(loadLatestBlogsNewsApi()),
    }
}

const mapStateToProps = state => ({
    ///...state,
    blogsReducer: state.blogsReducer,

});

export default connect(mapStateToProps, mapDispatchToProps)(LatestBlogsNews);