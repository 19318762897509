import React from 'react';
import PropTypes from "prop-types";
import FeedMedia from './FeedMedia';
import FeedDate from './FeedDate';

const NewsFeedSummary = (props) => {

    /* If the item is not defined or no data (means person), don't display it */
    if (props.item === undefined)
    {
        return '';
    }

    return <article className="news-feed">
            <div className="news-feed__source"> 
                <div className="news-feed__organization">{props.item.team.name} {props.item.team.surName}</div>
                <FeedDate dateClassName="news-feed__date" date={props.item.publicationDate} />
            </div>
            <div className="news-feed__data">
                <div className="news-feed__information">
                    <a href={props.item.link} rel="noreferrer" target="_blank" className="news-feed__title">{props.item.title}</a>
                    <div className="news-feed__summary">{props.item.summary}</div>
                </div>
                <div className="news-feed__media">
                    <FeedMedia team={props.item.team} url={props.item.externalImageAddress} link={props.item.link} linkClass="news-feed__media-link" imgClass="news-feed__image" />
                </div>
            </div>    
        </article>
}

/* properties : item and index are required */
NewsFeedSummary.propTypes = {
    item: PropTypes.object
};

export default NewsFeedSummary;