import React, {Component} from 'react';
import { connect } from "react-redux"; 
import { loadSportsEventsApi } from "../../actions/sportsEventsActions";
import DailyEvent from './DailyEvent';
import { Skeleton } from '@mui/material';

class SportsEvents extends Component {
    constructor(props) {
        super(props)
        // load the data from the initFn set in mapDispatchToProps
        this.props.initFn();
        setInterval(this.props.initFn, 60000);
      }

    render() {
        if (!this.props.sportsEventsReducer.loaded){
            
            const sk = Array.apply(null, Array(5)).map(n=> <div key={n} className="daily-event__item">
                <Skeleton variant="text" height={40} width="100%" />
            </div>);

            // Data not yet loaded
            return (
                <div className="daily-event">
                <div className="daily-event__date">
                    <div className="daily-event__day-name">
                        ...
                    </div>
                    <div className="daily-event__day">
                        Evènements
                    </div>
                </div>
                {sk}
            </div>
            );
        }
        
        if (this.props.sportsEventsReducer.data === undefined || this.props.sportsEventsReducer.data === null || this.props.sportsEventsReducer.data.dailyEvents.length === 0)
        {
            return (
                <div className="daily-event">
                <div className="daily-event__date">
                    <div className="daily-event__day-name">
                        ...
                    </div>
                    <div className="daily-event__day">
                        Evènements
                    </div>
                </div>
                <div className="daily-event__item daily-event__item--empty">
                        Aucun évènement prévu
                </div>
            </div>
            );
        }

        let dailyEvents = this.props.sportsEventsReducer.data.dailyEvents.map(function(item, index){        
            return <DailyEvent key={index} item={item}/>;});

        return (
            <React.Fragment>
                {dailyEvents}
            </React.Fragment>
        )
    }

}


const mapDispatchToProps = dispatch => {
    return {
        // Put in the props the dispatch to load the data
        initFn: () => dispatch(loadSportsEventsApi()),
    }
}

const mapStateToProps = state => ({
    ///...state,
    sportsEventsReducer: state.sportsEventsReducer,

});

export default connect(mapStateToProps, mapDispatchToProps)(SportsEvents);