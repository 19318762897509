import React from 'react';
import PropTypes from "prop-types";

const FeedDate = (props) => {

    const buildDate = () => {
        if (props.date === undefined && props.date === null && props.date === ''){
            return '';
        }
        else
        {
            const dt = new Date(props.date);
            const current = new Date();
            const seconds = (current - dt) / 1000;

            const m = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

            if (dt.getDay() === current.getDay()) {
                return "Aujourd'hui"
            }

            if (seconds < 172801) {
                let yesterday = new Date(current.setDate(current.getDate() - 1));
                if (dt.getDay() === yesterday.getDay()) {
                    return "Hier";
                }
            }

            let result = dt.getDate();
            if (result === 1)
            {
                result = "1er";
            }

            result = `${result} ${m[dt.getMonth()]} ${dt.getFullYear()}`;
            return result;
        }
    };

    return (
        <div className={props.dateClassName}>{buildDate()}</div>  
    );
}

/* properties :  */
FeedDate.propTypes = {
    date: PropTypes.string,
    dateClassName: PropTypes.string
};

export default FeedDate;