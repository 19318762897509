import React from 'react';
import PropTypes from "prop-types";
import { Config } from '../../config/index';

const CampaignItem = (props) => {

    if (props.item === undefined)
    {
        return '';
    }

    let img;
    if (props.item.link !== '')
    {
        img = <a href={props.item.link} rel="noreferrer" target="_blank" className="latest-campaigns__link"><img src={`${Config.cdn}/img/campaigns/${props.item.id}/${props.item.imageName}`} alt={props.item.title} className="latest-campaigns__img"/></a>
    }
    else
    {
        img = <img src={`${Config.cdn}/img/campaigns/${props.item.id}/${props.item.imageName}`} alt={props.item.title} className="latest-campaigns__img"/>
    }
    return (
        <div className="latest-campaigns__item">
            {img}
        </div>
    );
}

CampaignItem.propTypes = {
    item: PropTypes.object
};

export default CampaignItem;