import * as types from "../actions/actionsTypes";

// Reducer named statisticsExplorerReducer
const initialState = {
    hasError:false,
    loaded:false,
    leadersCategory: 'Batting',
    leadersTypeOfStatistics: {batting:'BattingAvg', pitching:'', catching:'', fielding:''},
    leadersForm: null,
    leadersData:null,
    leadersDataHistory:[],
    teamsForm: null,
    teamsData: []
};

export default function statisticsExplorerReducer(state = initialState, action) {
    switch (action.type) {
        case types.STATISTICS_BASE_LEADER_DATA:
            return {...state, leadersForm: action.payload, leadersData:null };
        case types.STATISTICS_LEADERS_SET_SEARCH:
            switch(action.payload.category)
            {
                case 'Batting':
                    return {...state, leadersCategory: action.payload.category, leadersTypeOfStatistics: {batting:action.payload.typeOfStatistics, pitching:'', catching:'', fielding:''} };
                case 'Pitching':
                    return {...state, leadersCategory: action.payload.category, leadersTypeOfStatistics: {batting:'', pitching:action.payload.typeOfStatistics, catching:'', fielding:''} };
                case 'Fielding':
                    return {...state, leadersCategory: action.payload.category, leadersTypeOfStatistics: {batting:'', pitching:'', catching:'', fielding:action.payload.typeOfStatistics} };
                case 'Catching':
                    return {...state, leadersCategory: action.payload.category, leadersTypeOfStatistics: {batting:'', pitching:'', catching:action.payload.typeOfStatistics, fielding:''} };
                default:
                    return state;
            }           
        case types.STATISTICS_LEADERS_KEY_DATA:
            return {...state, hasError: false, loaded:true, leadersData: action.payload};
        case types.STATISTICS_LEADERS_KEY_DATA_LOADING:
            return {...state, loaded:false, hasError:false, leadersData: null};
        case types.STATISTICS_LEADERS_KEY_DATA_LOADING_ERROR:
            return {...state, loaded:true, hasError:true, leadersData: null};

        default:
            return state;
    }
};