import React from 'react';
import PropTypes from "prop-types";
import StatisticsSummaryPerson from "./StatisticsSummaryPerson";

/* Statistics Summary Item for each item */
const StatisticsSummaryItem = (props) => {

    /* If the item is not defined or no data (means person), don't display it */
    if (props.item === undefined || props.item.data === undefined || props.item.data.length === 0)
    {
        return '';
    }
    /* For each person in data, build the list */
    var persons = props.item.data.map(function(person, index){ return <StatisticsSummaryPerson key={index} personItem={person} abbreviation={props.item.abbreviation} categoryName={props.item.name} loading={false} sport={props.sport} league={props.league} year={props.year} category={props.item.category} typeOfStatistics={props.item.typeOfStatistics}/>;});
    return (
        <div>
            {persons}          
        </div>
    );
}

/* properties : item and index are required */
StatisticsSummaryItem.propTypes = {
    item: PropTypes.object
};

export default StatisticsSummaryItem;